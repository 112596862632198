* {
  box-sizing: border-box;
  transition: all 0.3s ease;

  /* similar to what normalize.css does */
  line-height: 1.15;
}

html, body, #root {

  /* required on safari?
     https://stackoverflow.com/a/18037511/676798
   */
  position: relative;

  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color:  rgba(255, 235, 235, 0.1);

  -webkit-text-size-adjust: none; /* Never autoresize text, cargo-culting */

  /* to prevent scrolling left for back, doesn't work in safari */
  overscroll-behavior-x: none;
}

body {
  /* prevent scrolling body up/down */
  position: fixed;
}
